import React from 'react';
import { Container, Header, Image, Divider } from 'semantic-ui-react'

const NoMatch = () => (
  <Container textAlign={"center"}>
    <Header as={"h1"} style={{fontFamily: 'Glacial Indifference', color: '#231955'}}>Oops!</Header>
    <Divider hidden />
    <p style={{fontFamily: 'Glacial Indifference', fontSize: '1.5em', color: '#231955'}}>Looks like you're lost. Use the nav at the top of the page to find your way back home!</p>
    <Image centered src={"/images/404.jpeg"} size={"large"} />
  </Container>
)

export default NoMatch;