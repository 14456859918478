import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Menu, Image } from "semantic-ui-react";

const Nav = (props) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <Menu
      fluid
      stackable
      borderless
      widths={6}
      style={{ marginBottom: "25px" }}
    >
      <Menu.Item
        fitted={"horizontally"}
        onClick={() => history.push("/")}
        link
      >
        <Image src={'/images/logo.png'} alt="pencil logo" />
      </Menu.Item>
      {props.routes.map(({ to, name }) => (
        <Menu.Item
          active={location.pathname === to}
          key={to + name}
          link
          onClick={() => history.push(to)}
          content={<h3 style={{fontFamily: 'Glacial Indifference', color: '#231955'}}>{name}</h3>}
        />
      ))}
    </Menu>
  );
};

export default Nav;
