import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Testimonials from "./pages/Testimonials";
import NoMatch from "./pages/NoMatch"
import "./App.css";
import {Divider} from "semantic-ui-react";

const routes = [
  {
    to: "/",
    name: "Home",
  },
  {
    to: "/about",
    name: "About Sara",
  },
  {
    to: "/services",
    name: "Services",
  },
  {
    to: "/testimonials",
    name: "Testimonials",
  },
  {
    to: "/contact",
    name: "Contact",
  },
];

function App() {
  return (
    <Router>
      <div className="App">
        <Nav routes={routes} />
        <div className="pageContainer">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/testimonials">
              <Testimonials />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
          <Divider hidden />
          <Divider hidden />
        </div>
      </div>
    </Router>
  );
}

export default App;
