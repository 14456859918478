import React from 'react';
import {Link} from 'react-router-dom'
import { Container, Divider, Header, Image } from 'semantic-ui-react'

const Home = () => {
  return (
    <Container text textAlign="center" >
      <Header as={"h1"} style={{fontFamily: 'Glacial Indifference', color: '#231955'}}>Need a tutor?</Header>
      <Divider hidden />
      <Image centered src={'/images/sara.jpg'} size={"large"}/>
      <Divider hidden />
      <p style={{fontFamily: 'Glacial Indifference', fontSize: '1.5em', color: '#231955'}}>Sara is a CT and NY certified elementary teacher, credentialed in General and Special Education. She offers one-on-one and small-group tutoring sessions and enrichment programs.</p>
      <p style={{fontFamily: 'Glacial Indifference', fontSize: '1.5em', color: '#231955'}}><Link to="/contact">Contact Sara today</Link> to schedule your free consultation!</p>
    </Container>
  )
}

export default Home;