import React from "react";
import { Container, List, Header, Icon, Divider } from 'semantic-ui-react'

const fontFamily = {fontFamily: 'Glacial Indifference, serif', color: '#231955'}
const fontOverride = {fontFamily: 'Glacial Indifference, serif', fontSize: '1.5em', color: '#231955'}

const Services = () => {
  return (
    <Container>
      <Header as={"h1"} textAlign={"center"} style={fontFamily}>Sara Offers:</Header>
      <List relaxed>
        <List.Item>
          <Header as={"h1"} style={fontFamily} icon={() => <Icon style={{color: "#f8a000"}} name={"pencil"} />} content={"Academic Tutoring"}/>
          <Divider hidden/>
          <p style={fontOverride}>Sara offers academic tutoring to students grades K-6 in the following subject areas: Reading Skills, Writing Skills, Math Skills, Organization, Time Management, Homework Help, Study Skills, Test Preparation. Academic tutoring can be in-person or virtual.</p>
        </List.Item>
        <Divider hidden />
        <List.Item>
          <Header as={"h1"} style={fontFamily} icon={() => <Icon style={{color: "#f8a000"}} name={"book"} />} content={"Book Clubs"}/>
          <Divider hidden />
          <p style={fontOverride}>Sara facilitates age and content-appropriate book clubs that provide students with opportunities to connect to characters and texts through meaningful discussions and activities. Book Clubs meet for regular reading and discussion, rotating genres and topics of interest.</p>
        </List.Item>
        <Divider hidden />
        <List.Item>
          <Header as={"h1"} style={fontFamily} icon={() => <Icon style={{color: "#f8a000"}} name={"sun"} />} content={"Summer Enrichment"}/>
          <Divider hidden />
          <p style={fontOverride}>Keep your child's learning momentum going strong all summer! Your child will participate in interest-based enrichment projects, book clubs, and sustained learning in a fun, individualized way, even during time off from school. Enrichment sessions are offered during summer vacations as well as breaks throughout the school year.</p>
        </List.Item>
        <Divider hidden/>
        <List.Item>
          <Header as={"h1"} style={fontFamily} icon={() => <Icon style={{color: "#f8a000"}} name={"group"} />} content={"Learning Pods"}/>
          <Divider hidden />
          <p style={fontOverride}>Sara will design and implement curricula that will keep your child engaged and on grade-level, no matter what format your school has chosen for the coming year. Learning pods can be designed to fit your child’s schedule and can meet daily or several times per week.</p>
        </List.Item>
      </List>
    </Container>
  );
};

export default Services;
