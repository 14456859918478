import React from 'react';
import {Container, Image, Divider, Header} from 'semantic-ui-react'

const fontOverride = {fontFamily: 'Glacial Indifference', fontSize: '1.5em', color: '#231955'}

const About = () => {
  return (
    <div id="about">
      <Container text fluid textAlign={"center"}>
        <Header as={"h1"} style={{fontFamily: 'Glacial Indifference', color: '#231955'}}>About Sara:</Header>
        <Divider hidden />
        <Image centered src={"/images/saraBackyard.jpeg"} alt="Meet Sara" size={"large"}/>
        <Divider hidden />
        <p style={fontOverride}>Sara Rouse is an elementary school teacher and tutor in Fairfield County, with ten years of experience. She holds CT and NY state certifications in Elementary General Education and Elementary Special Education. She received her B.A. in Music Education from Montclair State University in New Jersey and a dual Master’s Degree in Childhood Education and Special Education from Touro College in New York City.</p>
        <p style={fontOverride}>Sara began her career in New York City, where she worked as a 1st and 2nd Grade teacher for two years at a charter school in Harlem. She then moved to PS 527 on the Upper East Side, where she taught 3rd grade for 8 years. In her classroom, Sara aims to create warm and inviting environments where students’ individual needs are targeted through rigorous and engaging instruction.</p>
        <p style={fontOverride}>Sara’s approach to private tutoring mirrors her biggest passion for classroom teaching: relationship-building in order to provide personal and meaningful instruction. In her one-on-one and small-group tutoring sessions, Sara augments in-school learning by providing support and enrichment activities that push children to maximize their educational potential.</p>
        <p style={fontOverride}>Sara lives in Fairfield, CT with her husband Jeremy and their son, Noah.</p>
      </Container>
    </div>
  )
}

export default About;