import React from "react";
import { Container, List, Header } from "semantic-ui-react";

const testimonials = [
  {
    name: "Alison",
    relationship: "Parent of 3rd Grader",
    text:
      "My son worked with Sara for two years and during that time it was so helpful to have someone with her knowledge of the curriculum. She demonstrated dedication to teaching him, despite his resistance at times. Sara has a lot of patience and was able to connect well with him even when the pandemic forced us to switch to zoom sessions. She also offered to connect with his teachers and to provide homework assignments to strengthen his learning and mastery of the material in between tutoring sessions. My son really enjoyed working with Sara!",
  },
  {
    name: "Danielle",
    relationship: "Parent of 2nd Grader",
    text:
      "My son has worked with Sara for over a year and we truly feel his success in reading, writing and continuing to love to learn is an enormous reflection on Sara. School work does not come easy to him but with Sara's ability to meet him and then push him to the next level has only helped him succeed. Her ingenuity when working with my son has him eager and excited to challenge himself. He has made such strides and we are truly grateful. Sara has virtually saved our children during the COVID pandemic and virtual/hybrid learning. Her patience, creativity and rapport with our children has been the consistency they needed this past year to feel successful.  Both of our children have different learning styles and needs and yet Sara is able to easily adapt and help our children find what methods work best for each of them.",
  },
  {
    name: "Alan",
    relationship: "Parent of 5th grader",
    text:
      "My daughter worked with Sara for school testing prep. She put my daughter's mind at ease within the first session. Her calming disposition coupled with her 'tool box' of tricks kept my daughter calm and yet fluent of the material.  Sara is well versed in the material and yet able to relate to the stresses of the test in general. My daughter was not only prepared with the material but her mindset changed due to working with Sara. She had the confidence to tackle it all.",
  },
];

const Testimonials = () => {
  return (
    <Container>
      <Header as={"h1"} textAlign={"center"} style={{fontFamily: 'Glacial Indifference', color: '#231955'}}>What Parents are Saying:</Header>
      <List relaxed>
        {testimonials.map((t) => (
          <List.Item key={t.text}>
            <p style={{fontFamily: 'Glacial Indifference', fontSize: '1.5em', color: '#231955'}}>{t.text}</p>
            <List.Description style={{fontFamily: 'Glacial Indifference', textAlign: 'right', color: '#231955'}}>- {t.name}, {t.relationship}</List.Description>
          </List.Item>
        ))}
      </List>
    </Container>
  );
};

export default Testimonials;
