import React from "react";
import { Container, Header, Button, Icon } from 'semantic-ui-react'

const Contact = () => {
  const mailToLink = 'mailto:saranicolerouse@gmail.com?subject=Tutoring%20Inquiry'
  return (
    <Container textAlign={"center"}>
      <Header as={"h1"} textAlign={"center"} style={{fontFamily: 'Glacial Indifference', color: '#231955'}}>Get in touch!</Header>
      <p style={{fontFamily: 'Glacial Indifference', fontSize: '1.5em', color: '#231955'}}>Contact Sara to schedule your free 30 minute consultation.</p>
      <Button as="a" color="blue" target="_blank" href={mailToLink}><Icon name="envelope"/>Email Sara</Button>
    </Container>
  );
};

export default Contact;
